const ApiRoutes = {
    login: "/api/v1/auth/login",
    logout: "api/v1/auth/logout",
    passId: "api/v1/outlet-portal/verify-user",
    ouletBenefits: "/api/v1/outlet-portal/outlets-benefits",
    redeemOffer: "/api/v1/outlet-portal/redeem",
    refreshToken: "/api/v1/auth/token",
    outletRedemption: "/api/v1/outlet-portal/redemptions",
    passes:"/api/v1/admin/passes",
    partners:"/api/v1/admin/partners",
    benefits:"/api/v1/admin/benefits",
    Amenity:"/api/v1/admin/amenities",
    Category:"/api/v1/admin/categories",
    Outlet:"/api/v1/admin/outlets",
    Merchant:"/api/v1/admin/merchants",
    categoryListing: "/api/v1/admin/categories",
    imgUpload: "/development/media",
    createCategory: "/api/v1/admin/categories/create",
    deleteCategory: "/api/v1/admin/categories/delete",
    amenities: "/api/v1/admin/amenities",
    createAmenity: "/api/v1/admin/amenities/create",
    deleteAmenity: "/api/v1/admin/amenities/delete",
    createBenefit:"/api/v1/admin/benefits/create",
    createOutlet:"/api/v1/admin/outlets/create",
    createPass:"/api/v1/admin/passes/create",
    deleteBenefit:"/api/v1/admin/benefits/delete",
    deletePass:"/api/v1/admin/passes/delete",
    deleteOutlet:"/api/v1/admin/benefits/delete",
    userListing: "/api/v1/admin/users",
    addUser: "/api/v1/admin/users/create",
    verifyQRCode: "/api/v1/outlet-portal/verify-qr"
};
export default ApiRoutes;
