import ApiRoutes from "../utils/apiRoutes/routes";
import { Get, Put } from "../utils/services/request2";


export const fetchPartnerList = async (params) => {
    try {
        const response = await Get(ApiRoutes.partners, null, params);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const fetchPartnerById = async (id) => {
    try {
        const response = await Get(`${ApiRoutes.partners}/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const updatePartner = async (id,data) => {
    try {
        const response = await Put(`${ApiRoutes.partners}/${id}`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const deletePartner = async (id) => {
    try {
        const response = await Put(`${ApiRoutes.partners}/delete/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const createPartner = async (data) => {
    try {
        const response = await Put(`${ApiRoutes.passes}/create`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}