import React from 'react';
import { UserOutlined, VideoCameraOutlined } from '@ant-design/icons';

// Define and export the menu items
const outletMenuItems = [
  {
    key: '/',
    icon: <UserOutlined />,
    label: 'Benefits',
  },
  {
    key: 'redemption',
    icon: <VideoCameraOutlined />,
    label: 'Outlet Redemption',
  },
];

export default outletMenuItems;
