
import service from "./axiosBase";

export const Get = (path, headers = null) => {
    return service.get(path, { ...(headers && { headers }) });
};

export const Post = (path, params, headers = null) => {
    return service.post(path, params, { ...(headers && { headers }) });
};

export const Put = (path, params, headers = null) => {
    return service.put(path, params, { ...(headers && { headers }) });
};
