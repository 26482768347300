
export default function PageNotFound(props) {
    return (
  <div className="under-maintenance px-12 h-screen flex items-center justify-center">
    <div className="text-center flex flex-col items-center justify-center test">
      <h1 className="">
          404
      </h1>
      <h2>PAGE NOT FOUND</h2>
    </div>
  </div>
    );
  }
  