import { Input, Select } from 'antd';
import React, { useRef } from 'react';
import moment from 'moment'; // Import Moment.js
import './style.scss';
import DatePicker from 'react-date-picker';

const Index = ({ label, placeholder, name, onChange, type, options, required, value, multiple }) => {

    const inpRef = useRef();

    const handleDateChange = (date) => {
        const formattedDate = date ? date.format("YYYY-MM-DD") : null;
        onChange({ target: { value: formattedDate, name: name } });
    };

    const handleDropdownChange = (e) => {
        onChange({ target: { value: e, name: name } });
    };

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        onChange({ target: { files, name: name } });
    }

    const fileBtnClick = () => {
        inpRef?.current?.click();
    }
    if (type === "file") {
        console.log("Value", value)
    }

    return (
        <div className='input__with__label__main'>
            <p>{label}{required ? "*" : ""}</p>
            {type === "file" ?
                <div className='antd__inp flex items-center gap-2'>
                    <div className='custom__file__drag' onDrop={fileDrop} >
                        <input className='w-0' ref={inpRef} type="file" name={name} onChange={onChange} />
                        <button onClick={fileBtnClick}>
                            +
                        </button>
                    </div>
                    <div className='flex items-center gap-2'>
                        {value?.map((v, i) => {
                            return <div key={i}>
                                <img className='file__img' src={v?.path} />
                            </div>
                        })}
                    </div>
                </div>
                : type === "date" ? (
                    <DatePicker
                        value={value ? moment(value) : null} // Convert to Moment.js
                        onChange={handleDateChange}
                        name={name}
                        className="antd__inp"
                    />
                ) : type === "dropdown" ? (
                    <Select
                        className='antd__inp'
                        defaultValue="lucy"
                        onChange={handleDropdownChange}
                        placeholder={placeholder}
                        value={value}
                        mode={multiple && "multiple"}
                        options={options ?? [
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                        ]}
                    />
                ) : (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                        type={type}
                        name={name}
                        className="antd__inp"
                    />
                )}
        </div>
    );
};

export default Index;
