// 
import React, { useEffect, useState, useCallback } from 'react';
import Wrapper from '../../layouts/wrapper/wrapper';
import { Table, Button, Space, Popconfirm, message, Switch, Tag } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { Get, Put } from '../../utils/services/request2';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


const Categories = () => {

    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrorMsg] = useState("");
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    useEffect(() => {
      getCategoryData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const getCategoryData = useCallback(async (page, limit) => {
      setLoading(true);
      try {
          const offset = (page - 1) * limit;
          const res = await Get(`${ApiRoutes.categoryListing}?offset=${offset}&limit=${limit}`);
          setTableData(res?.data?.data?.records || []);
          setTotal(res?.data?.data?.count || 0);
          setErrorMsg(res?.data?.data?.records?.length === 0 ? 'No data found' : '');
          setLoading(false);
      } catch (err) {
          setErrorMsg(err?.response?.data?.message || 'Failed to fetch data');
          setTableData([]);
          setTotal(0);
          setLoading(false)
          
      } 
  }, []);


  const handleTableChange = (pagination) => {
      const { current, pageSize } = pagination;
      if (current !== currentPage || pageSize !== pageSize) {
          setCurrentPage(current);
          setPageSize(pageSize);
      }
  };


  const handleActiveDeactive = async (id, record) => {
    const data = {is_active: !record.is_active}
    try {
     const res = await Put(`${ApiRoutes.categoryListing}/${id}`, data);
      message.success(res?.data?.message);
      getCategoryData(currentPage, pageSize);
    } catch (err) {
      message.error('Failed to deactivated category');
    }
  };



    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Arabic Name',
          dataIndex: 'arabic_name',
          key: 'arabic_name',
        },
        {
          title: 'Media',
          dataIndex: 'medias',
          key: 'medias',
          render: (text, record) => {
              return <div className="flex items-center gap-2">{record?.medias[0] && <img className='img__in__table' alt='not found' src={record?.medias[0]?.path} />}</div>
          },
          width:150
      },
      {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'status',
        render: (text, record) => {
            return  <Tag color="#c66238">{record?.is_active? "Activated": "Deactivated"}</Tag>
        },
        width:150
    },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'), // Format the date
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
              <Space size="middle">
                {/* Edit Button */}
                <Button
                  onClick={() => {
                    navigate(`/edit-category/${record.id}`);
                  }}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                  icon={<EditOutlined />}
                />
      
                <Popconfirm
                  title={record.is_active ? "Are you sure you want to deactivate this category?" : "Are you sure you want to activate this category?"}
                  onConfirm={() => handleActiveDeactive(record.id, record)} // Confirm delete action
                  okText="Yes"
                  cancelText="No"
                >

                <Switch checked={record.is_active} />

                </Popconfirm>
              </Space>
            ),
          },
      ];
    



    const redeem = () => {
        return (
            <div className="responsive-table-container">
                <div className='flex justify-end mb-2'>
            <Button type='primary' onClick={()=>{ navigate('/add-category')}}>Add Category</Button>
            </div>
            <Table
              columns={columns}
              dataSource={tableData}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: total,
              }}
              onChange={handleTableChange}
              locale={{ errMsg }}
              scroll={{ x: 600 }} 
            />
          </div>
        )
    }


    return (
        <Wrapper Comp={redeem} />
    );
}

export default Categories;
