import axios from "axios";
import config from "../../config";
import { getToken } from "../userData/userData";

const baseURL = config.baseURL;

const service = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    APP_VERSION: config.appVersion,
  },
});

service.interceptors.request.use((req) => {
  let token = getToken()
  if (token) {
    let headers = { ...req.headers };
    req.headers = {
      Authorization: `Bearer ${token}`,
      ...headers,
    };
  } else {
    let headers = { ...req.headers };
    req.headers = {
      ...headers,
    };
  }
  return req;
});

service.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    if (err?.response?.status === 401) {
      window.location.href = "/login"
      localStorage.clear();
    } else if (err?.response?.status === 502 || err?.response?.status === 503) {
      window.location.href = "/underMaintenance"
    } else {
      return Promise.reject(err);
    }
  }
);

export default service;
