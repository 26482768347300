// 
import React, { useEffect, useState, useCallback } from 'react';
import Wrapper from '../../layouts/wrapper/wrapper';
import { Form, Input, Button, message, Select } from 'antd';
import { Post } from '../../utils/services/request2';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { useNavigate } from 'react-router-dom';
import { fetchOutletList } from '../../apiFunctions/outlets';
import CustomAutoComplete from '../../components/inputs/autocomplete';


const AddUser = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [outletOptions, setOutletOptions] = useState([]);
    const [userGroupOptions, setUserGroupOptions] = useState([{label: "Admin", value: 1}, {label: "Customer", value: 2}]);


  
    const handleSubmit = async (values) => {
        setLoading(true)
      const formData = {
        ...values,
      };  

      try {
        const res = await Post(ApiRoutes.addUser, formData);
        setLoading(false)
        message.success('User added successfully!');
        form.resetFields();
      } catch (err) {
        message.error('something went wrong!');
        setLoading(false)
      }
   
    };


    const fetchDropdownData = async (text) => {
        const [outlets] = await Promise.all([fetchOutletList(text ? {name:text}:{})]);
        const outletOptions = outlets?.data?.records?.map(v => ({ label: v?.name, value: v?.id }));
        return outletOptions
    }


    const redeem = () => {
        return (
            <Form
            form={form}
            name="custom-form"
            layout="vertical"
            onFinish={handleSubmit}
            style={{ maxWidth: '600px', margin: '50px auto' }}
          >
            <Button className='mb-10' onClick={() => { navigate("/users") }} type="primary">
              Back
            </Button>
      
            <Form.Item
              label="Full Name"
              name="full_name"
              rules={[{ required: true, message: 'Please Enter your Full Name!' }]}
            >
              <Input placeholder="Enter Full Name" />
            </Form.Item>
      
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please Enter your Email!' },
                { type: 'email', message: 'Please Enter a valid Email!' },
              ]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>

            <Form.Item
          label="User Group"
          name="user_group_id"
          rules={[{ required: true, message: "Please Select the User Group!" }]}
        >
          <Select
            className='antd__inp'
            options={userGroupOptions}
            placeholder="Select User Group"
          />
        </Form.Item>

            <Form.Item
          label="Outlet"
          name="outlet_id"
          rules={[{ required: true, message: "Please Select the Outlet!" }]}
        >
          <CustomAutoComplete placeholder={"Select Outlet"} name={'outlet_id'} fetchData={fetchDropdownData} form={form} />
        </Form.Item>
      
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please Enter your password!' },
                { min: 6, message: 'Password must be at least 6 characters long!' },
              ]}
            >
              <Input.Password placeholder="Enter Password" />
            </Form.Item>
      
            <Form.Item>
              <Button className='w-full' disabled={loading} loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
    }


    return (
        <Wrapper Comp={redeem} />
    );
}

export default AddUser;
