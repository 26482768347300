// 
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../layouts/container/container';
import { Button, Form, Input, Alert } from 'antd';
import iPassLogo from '../../assets/images/svg/iPassLogo.svg'
import "./login.scss"
import { useAuth } from '../../context';
import { Get, Post } from '../../utils/services/request';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { Encoding } from '../../functions/base64';



const Login = () => {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false)
    const [res, setRes] = useState(null)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null);


    const { isAuthenticated, login } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, []);


    const onFinish = async (values) => {
        try {
            setLoading(true);
            let payload = values;
            const res = await Post(ApiRoutes.login, payload);
            setLoading(false);
            setRes(res);
            if (res?.data?.status) {
                const userData = res?.data?.data;
                localStorage.setItem("userData", Encoding(JSON.stringify(userData)));
                localStorage.setItem("access_token", Encoding(JSON.stringify(userData?.access_token)));
                localStorage.setItem("refreshToken", Encoding(JSON.stringify(userData?.refresh_token)));
                localStorage.setItem("userType",Encoding(JSON.stringify(res?.data?.data?.user?.management?.user_groups[0]?.name)));
                setError(false);
                form.resetFields();
                login();
                if(payload.email === "mohidkhan@mybookqatar.com"){
                    navigate('/users');
                }else{
                    navigate('/');
                }
            }

            else if (res && !res?.data?.status) {
                setError(true);
                setErrorMsg(res?.data?.message);
            }


        } catch (err) {
            console.log(err,"catch error")
            setError(true);
            setErrorMsg(err?.response?.data?.message)
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('error informa:', errorInfo);
    };


    return (
        <div className="login-form-container">
            <Container>
                {error && (
                    <Alert
                        type="error"
                        message={errorMsg}
                        className="mb-4"
                    />
                )}
                <div className='logoWrapper'>
                    <img src={iPassLogo} alt="logo" />
                </div>
                <h4 className='siginHeading'>Sign In To Admin</h4>
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input type="email" placeholder='Email' />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password
                            className='password-input'
                            placeholder="Password"
                            visibilityToggle={{
                                visible: passwordVisible,
                                onVisibleChange: setPasswordVisible,
                            }}
                        />
                    </Form.Item>


                    <Form.Item
                    >
                        <div className='signinWrapper'>
                            <Button loading={loading}
                                disabled={loading} type="primary" htmlType="submit">
                                Sign in
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Container>
        </div>
    );
}

export default Login;
