import React, { useEffect, useState } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Form, Input, Button, message, Spin, Select } from "antd";
import DynamicMediaUploader from "../../assets/hooks/multiImagesUploder";
import { Get, Put } from "../../utils/services/request2";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCategoryList } from "../../apiFunctions/categories";
import { fetchMerchantList } from "../../apiFunctions/merchants";
import CustomAutoComplete from "../../components/inputs/autocomplete";

const EditOutlet = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    getBenefitByID();
  }, []);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        name: formData.name,
        arabic_name: formData.arabic_name,
        merchant_id:formData.merchant_id,
        categories:formData?.categories?.map((v)=> v?.id),
        medias:formData?.medias
      });

      if (formData.medias && formData?.medias?.length > 0) {
        const initialFileList = formData?.medias?.map((file, index) => ({
          id: file?.id, // Unique identifier for each file
          url: file?.path, // URL for the file preview
          size: file?.size
        }));
        setFileList(initialFileList);
      }
    }
  }, [formData, form]);

  const getBenefitByID = async () => {
    try {
      setLoading(true);
      const res = await Get(`${ApiRoutes.Outlet}/${id}`);
      setFormData(res?.data?.data);
      setLoading(false);
    } catch (err) {
      message.error("Something went wrong!");
      setLoading(false);
    }
  };

  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const formDataToSubmit = {
      ...values,
      media: fileList?.map(v => (v?.id ? { path: v?.url, size: v?.size ?? 1, id: v?.id } : { path: v?.url,size: v?.size ?? 1, })),
    };

    try {
      const res = await Put(`${ApiRoutes.Outlet}/${id}`, formDataToSubmit);
      setLoading(false);
      message.success("Outlet updated successfully!");
      navigate("/outlets");
    } catch (err) {
      message.error("Something went wrong!");
      setLoading(false);
    }
  };

  const getUploadedList = (list) => {
    console.log(list, "Uploaded Files");
  };
  
  const fetchMerchants = async (value) => {
    try {
      const res = await fetchMerchantList(value ? { name: value } : {});
      return res?.data?.records?.map((v) => ({ label: v?.name, value: v?.id }));
    } catch (error) {
      return [];
    }
  }

  const fetchCategories = async (text) => {
    const [categories] = await Promise.all([fetchCategoryList(text ? {name:text}:{})]);
    const categoryOptions = categories?.data?.records?.map(v => ({ label: v?.name, value: v?.id }));
    return categoryOptions;
  }


  const redeem = () => {
    return (
      <Spin spinning={loading}>
        <Form
        form={form}
        name="custom-form"
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: "600px", margin: "50px auto" }}
      >
        <Button
          className="mb-10"
          onClick={() => {
            navigate("/outlets");
          }}
          type="primary"
        >
          Back
        </Button>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please Enter Name!" },
            { min: 3, message: "Name must be at least 3 characters!" },
            { max: 50, message: "Name cannot exceed 50 characters!" },
          ]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          label="Arabic Name"
          name="arabic_name"
        >
          <Input placeholder="Enter Arabic Name" />
        </Form.Item>

        <Form.Item
          label="Merchant"
          name="merchant_id"
          rules={[{ required: true, message: "Please Select a Merchant!" }]}
        >
            <CustomAutoComplete placeholder={"Select Merchant"} name={'merchant_id'} fetchData={fetchMerchants} form={form} />
        </Form.Item>

        <Form.Item
          label="Categories"
          name="categories"
          rules={[{ required: true, message: "Please Select Categories!" }]}
        >
          <CustomAutoComplete placeholder={"Select Categories"} name={'categories'} fetchData={fetchCategories} multiple form={form} />
        </Form.Item>

        <Form.Item label="Media Files">
        <DynamicMediaUploader setFileList={setFileList} fileList={fileList} />
        </Form.Item>
        <Form.Item>
          <Button
            className="w-full"
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      </Spin>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default EditOutlet;
