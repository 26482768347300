// 
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Wrapper from '../../layouts/wrapper/wrapper';
import { Table } from 'antd';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { Get } from '../../utils/services/request2';
import debounce from "lodash/debounce";
import moment from 'moment';
import SearchInput from '../../components/searchInput/searchIput';


const OutletRedemption = () => {


    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrorMsg] = useState("");
    const [err, setErr] = useState(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
      getOutletRedemptionData(currentPage, pageSize, searchText);
  }, [currentPage, pageSize, searchText]);

  const getOutletRedemptionData = useCallback(async (page, limit, text = "") => {
      setLoading(true);
      try {
          const offset = (page - 1) * limit;
          const res = await Get(`${ApiRoutes.outletRedemption}?offset=${offset}&limit=${limit}&pass_number=${text}`);
          setTableData(res?.data?.data?.records || []);
          setTotal(res?.data?.data?.count || 0);
          setErrorMsg(res?.data?.data?.records?.length === 0 ? 'No data found' : '');
          setLoading(false);
      } catch (err) {
          setErrorMsg(err?.response?.data?.message || 'Failed to fetch data');
          setTableData([]);
          setTotal(0);
          setLoading(false)
          
      } 
  }, []);


  const handleTableChange = (pagination) => {
      const { current, pageSize } = pagination;
      if (current !== currentPage || pageSize !== pageSize) {
          setCurrentPage(current);
          setPageSize(pageSize);
      }
  };

  const handleSearch = useMemo(() => debounce((e) => {
      const text = e.target.value;
      setSearchText(text)
      if (text.length > 2) {
        getOutletRedemptionData(currentPage, pageSize, text);
      }else if(!text.length){
        getOutletRedemptionData(currentPage, pageSize, text);
      }
  }, 400), []);


    const columns = [
        {
          title: 'Benefit',
          dataIndex: ['benefit', 'name'],
          key: 'benefit',
        },
        {
          title: 'Category',
          dataIndex: ['category', 'name'],
          key: 'category',
        },
        {
          title: 'Merchant',
          dataIndex: ['merchant', 'name'],
          key: 'merchant',
        },
        {
            title: 'Outlet',
            dataIndex: ['outlet', 'name'],
            key: 'outlet',
          },
          {
            title: 'Pass',
            dataIndex: ['pass', 'name'],
            key: 'pass',
          },
          {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'), // Format the date
          },
      ];
    



    const redeem = () => {
        return (
            <div className="responsive-table-container">
              <div className='py-4'>
              {/* <SearchInput value={searchText} onChange={(e)=> handleSearch(e)} placeholder="Search by pass number" /> */}
              </div>
            <Table
              columns={columns}
              dataSource={tableData}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: total
              }}
              onChange={handleTableChange}
              locale={{ errMsg }}
              scroll={{ x: 600 }} // Allows horizontal scrolling
            />
          </div>
        )
    }


    return (
        <Wrapper Comp={redeem} />
    );
}

export default OutletRedemption;
