//
import React, { useEffect, useState, useCallback } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Table, Button, Space, Popconfirm, message, Switch, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { Get, Put } from "../../utils/services/request2";
import { useNavigate } from "react-router-dom";
import { formateDate } from "../../utils/functions/common";

const Passes = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrorMsg] = useState("");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    getCategoryData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const getCategoryData = useCallback(async (page, limit) => {
    setLoading(true);
    try {
      const offset = (page - 1) * limit;
      const res = await Get(
        `${ApiRoutes.passes}?offset=${offset}&limit=${limit}`
      );
      setTableData(res?.data?.data?.records || []);
      setTotal(res?.data?.data?.count || 0);
      setErrorMsg(
        res?.data?.data?.records?.length === 0 ? "No data found" : ""
      );
      setLoading(false);
    } catch (err) {
      setErrorMsg(err?.response?.data?.message || "Failed to fetch data");
      setTableData([]);
      setTotal(0);
      setLoading(false);
    }
  }, []);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    if (current !== currentPage || pageSize !== pageSize) {
      setCurrentPage(current);
      setPageSize(pageSize);
    }
  };

  const handleDelete = async (id) => {
    try {
      await Put(`${ApiRoutes.deletePass}/${id}`);
      message.success("Pass deleted successfully");
      getCategoryData(currentPage, pageSize);
    } catch (err) {
      message.error("Failed to delete Pass");
    }
  };

  const handleActiveDeactive = async (id, record) => {
    const data = {is_active: !record.is_active}
    try {
     const res = await Put(`${ApiRoutes.passes}/${id}`, data);
      message.success(res?.data?.message);
      getCategoryData(currentPage, pageSize);
    } catch (err) {
      message.error('Failed to deactivated pass');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200
    },
    {
      title: 'Arabic Name',
      dataIndex: 'arabic_name',
      key: 'arabicName',
      width: 200
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 150
    },
    {
      title: 'Discounted Price',
      dataIndex: 'discounted_price',
      key: 'discountedPrice',
      width: 150
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      width: 150
    },
    {
      title: 'Estimated Savings',
      dataIndex: 'estimated_savings',
      key: 'estimatedSavings',
      width: 220
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (text, record) => {
        return <span>{formateDate(record.start_date)}</span>
      },
      width: 120
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'endDate',
      render: (text, record) => {
        return <span>{formateDate(record.end_date)}</span>
      },
      width: 120
    },
    {
      title: 'Free',
      dataIndex: 'is_free',
      key: 'free',
      render: (text, record) => {
        return <span>{record?.is_free ? "Yes" : "No"}</span>
      },
      width: 120
    },
    {
      title: 'Purchase',
      dataIndex: 'is_purchase',
      key: 'purchase',
      render: (text, record) => {
        return <span>{record?.isis_purchase_free ? "Yes" : "No"}</span>
      },
      width: 120
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'active',
      render: (text, record) => {
        return  <Tag color="#c66238">{record?.is_active? "Activated": "Deactivated"}</Tag>
    },
      width: 120
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          {/* Edit Button */}
          <Button
            onClick={() => {
              navigate(`/edit-pass/${record.id}`);
            }}
            style={{ border: 'none', backgroundColor: 'transparent' }}
            icon={<EditOutlined />}
          />

          <Popconfirm
            title={record.is_active ? "Are you sure you want to deactivate this pass?" : "Are you sure you want to activate this pass?"}
            onConfirm={() => handleActiveDeactive(record.id, record)} // Confirm delete action
            okText="Yes"
            cancelText="No"
          >

          <Switch checked={record.is_active} />

          </Popconfirm>
        </Space>
      ),
  },
  ]

  const redeem = () => {
    return (
      <div className="responsive-table-container">
        <div className="flex justify-end mb-2">
          <Button
            type="primary"
            onClick={() => {
              navigate("/add-pass");
            }}
          >
            Add Pass
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
          }}
          onChange={handleTableChange}
          locale={{ errMsg }}
          scroll={{ x: 600 }}
        />
      </div>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default Passes;
