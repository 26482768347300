import { message } from "antd";

export function Encoding(string) {
    const codeUnits = new Uint16Array(string?.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return window.btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
}

export function Decoding(encoded) {
    const binary = window.atob(encoded);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  }
  
export const notifyError = (msg) => {
  message.error(msg);
}