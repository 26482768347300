import ApiRoutes from "../utils/apiRoutes/routes";
import { Get, Put } from "../utils/services/request2";


export const fetchCategoryList = async (params) => {
    try {
        const response = await Get(ApiRoutes.Category, null, params);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const fetchCategoryById = async (id) => {
    try {
        const response = await Get(`${ApiRoutes.Category}/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const updateCategory = async (id,data) => {
    try {
        const response = await Put(`${ApiRoutes.Category}/${id}`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const deleteCategory = async (id) => {
    try {
        const response = await Put(`${ApiRoutes.Category}/delete/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const createCategory = async (data) => {
    try {
        const response = await Put(`${ApiRoutes.passes}/create`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}