import React, { useEffect, useState } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Form, Input, Button, message, Spin, Select } from "antd";
import DynamicMediaUploader from "../../assets/hooks/multiImagesUploder";
import { Get, Put } from "../../utils/services/request2";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPartnerList } from "../../apiFunctions/partners";
import { preventToEnterSymbol } from "../../utils/functions/common";
import { CURRENCY_LIST } from "../../utils/constants";
import moment from "moment";
import DatePicker from 'react-date-picker';
import CustomAutoComplete from "../../components/inputs/autocomplete";



const EditPass = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  const [partnerOptions, setPartnerOptions] = useState([])
  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {
    getBenefitByID();
  }, []);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        name: formData.name,
        arabic_name: formData.arabic_name,
        price: formData?.price,
        discounted_price: formData?.discounted_price,
        currency: formData?.currency,
        estimated_savings: formData?.estimated_savings,
        start_date: formData?.start_date ? moment(formData.start_date, dateFormat) : null, // Convert to moment
        end_date: formData?.end_date ? moment(formData.end_date, dateFormat) : null, // Convert to moment
        is_free: formData?.is_free,
        is_purchase: formData?.is_purchase,
        is_active: formData?.is_active,
        priority: formData?.priority,
        partner_id: formData?.partners?.map(v => v?.id),
        medias: formData?.medias
      });

      if (formData?.medias && formData?.medias?.length > 0) {
        const initialFileList = formData?.medias?.map((file, index) => ({
          id: file?.id, // Unique identifier for each file
          url: file?.path, // URL for the file preview
          size: file?.size
        }));
        setFileList(initialFileList);
      }
    }
  }, [formData, form]);

  const getBenefitByID = async () => {
    try {
      setLoading(true);
      const res = await Get(`${ApiRoutes.passes}/${id}`);
      setFormData(res?.data?.data);
      setLoading(false);
    } catch (err) {
      message.error("Something went wrong!");
      setLoading(false);
    }
  };

  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const formDataToSubmit = {
      ...values,
      media: fileList?.map(v => (v?.id ? { path: v?.url, size: v?.size ?? 1, id: v?.id } : { path: v?.url,size: v?.size ?? 1, })),
      estimated_savings: Number(values.estimated_savings),
      price: Number(values.price),
      discounted_price: Number(values.discounted_price),
      currency: 1,
      start_date: moment(new Date(values.start_date)).format("YYYY-MM-DD"),
      end_date: moment(new Date(values.end_date)).format("YYYY-MM-DD"),
      is_free: null,
      priority: Number(values.priority),
    };

    try {
      const res = await Put(`${ApiRoutes.passes}/${id}`, formDataToSubmit);
      setLoading(false);
      message.success("Pass updated successfully!");
      form.resetFields();
      setFileList([]);
      navigate("/passes");
    } catch (err) {
      message.error("Something went wrong!");
      setLoading(false);
    }
  };

  const getUploadedList = (list) => {
    console.log(list, "Uploaded Files");
  };

  const getPartners = async (text) => {
    const { data } = await fetchPartnerList(text ? {name:text} :{});
    return data?.records?.map(v => ({ label: v?.name, value: v?.id }))
  }


  const redeem = () => {
    return (
      <Spin spinning={loading}>
        <Form
          form={form}
          name="custom-form"
          layout="vertical"
          onFinish={handleSubmit}
          style={{ maxWidth: "600px", margin: "50px auto" }}
        >
          <Button
            className="mb-10"
            onClick={() => {
              navigate("/passes");
            }}
            type="primary"
          >
            Back
          </Button>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please Enter your Name!" }]}
          >
            <Input placeholder="Enter Name" />
          </Form.Item>

          <Form.Item
            label="Arabic Name"
            name="arabic_name"
          >
            <Input placeholder="Enter Arabic Name" />
          </Form.Item>
          <Form.Item
            label="Price"
            name="price"
            rules={[{ required: true, message: "Please Enter Price!" }]}
          >
            <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Price" />
          </Form.Item>
          <Form.Item
            label="Discounted Price"
            name="discounted_price"
          >
            <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Discounted Price" />
          </Form.Item>
          <Form.Item
            label="Currency"
            name="currency"
            rules={[{ required: true, message: "Please Select Currency!" }]}
          >
            {/* <Input placeholder="Enter Currency" /> */}
            <Select
              className='antd__inp'
              options={CURRENCY_LIST}
              placeholder="Select Currency"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            label="Estimated Savings"
            name="estimated_savings"
          >
            <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Estimated Savings" />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="start_date"
            rules={[{ required: true, message: "Please Enter Start Date!" }]}
          >
            <DatePicker placeholder="Select Start Date" className="w-full custom__calendar" />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="end_date"
            rules={[{ required: true, message: "Please Enter End Date!" }]}
          >
            <DatePicker placeholder="Select End Date" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Free"
            name="is_free"
          >
            <Select
              className='antd__inp'
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              placeholder="Select Free"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            label="Purchase"
            name="is_purchase"
          >
            <Select
              className='antd__inp'
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              placeholder="Select Free"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            label="Active"
            name="is_active"
          >
            <Select
              className='antd__inp'
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              placeholder="Select Active"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            label="Partners"
            name="partner_id"
            rules={[{ required: true, message: "Please Select Partners!" }]}
          >
            <CustomAutoComplete placeholder={"Select Partners"} name={'partner_id'} fetchData={getPartners} form={form} multiple />
          </Form.Item>
          <Form.Item
            label="Priority"
            name="priority"
            rules={[{ required: true, message: "Please Select Priority!" }]}
          >
            <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Priority" />
          </Form.Item>

          <Form.Item label="Media Files">
            <DynamicMediaUploader setFileList={setFileList} fileList={fileList} />
          </Form.Item>
          <Form.Item>
            <Button
              className="w-full"
              disabled={loading}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default EditPass;
