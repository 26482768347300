import { Table } from "antd"
import { formateDate } from "../../../../utils/functions/common";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"



const PassTable = ({ data, pagination, onChange, handleEditClick, handleDelClick }) => {


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200
        },
        {
            title: 'Arabic Name',
            dataIndex: 'arabic_name',
            key: 'arabicName',
            width: 200
        },
      
        {
            title: 'Active',
            dataIndex: 'is_active',
            key: 'active',
            render: (text, record) => {
                return <span>{record?.is_active ? "Yes" : "No"}</span>
            },
            width: 120
        },
        // {
        //     title: 'Priority',
        //     dataIndex: 'priority',
        //     key: 'priority',
        //     width: 120
        // },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            width: 120,
            render: (text, record) => {
                console.log("Record", record)
                return <div className="flex gap-3">
                    <button onClick={()=> handleEditClick(record)}>
                        <EditOutlined />
                    </button>
                    <button onClick={()=> handleDelClick(record)}>
                        <DeleteOutlined />
                    </button>
                </div>
            }
        }
    ]

    return <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: "max-content", y: "max-content" }}
        pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
        }}
        onChange={onChange}
    />
}

export default PassTable;