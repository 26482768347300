import React, { useEffect, useState } from "react";
import Wrapper from "../../../../layouts/wrapper/wrapper";
import Redeem from "../../../../components/redeem/redeem";
import { Table } from "antd";
import PassTable from "./table";
import { useNavigate } from "react-router-dom";
import { fetchPartnerList } from "../../../../apiFunctions/partners";


const PassList = () => {

    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalItems, setTotalItems] = useState(25)

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();

    const getList = async () => {
        try {
            setIsLoading(true)
            const response = await fetchPartnerList({
                offset: currentPage,
                limit: pageSize
            });
            setCurrentPage(currentPage+1)
            setData(response?.data?.records ?? [])
            setIsLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getList()
    }, [])


    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
    };

    const handleDelClick = async(data) => {
        // const response = await deletePass(data?.id)
    }

    const handleEditClick =async (data) => {
        navigate(`/admin/partner/update/${data?.id}`)
    }

    return <div>
        <h2>
            Pass List
        </h2>
        <PassTable
            data={data}
            pagination={{
                current: currentPage,
                pageSize,
                total: totalItems
            }}
            onChange={handleTableChange}
            handleDelClick={handleDelClick}
            handleEditClick={handleEditClick}
        />
    </div>
}


const Index = () => {
    return <>
        <Wrapper Comp={PassList} />
    </>
}

export default Index;