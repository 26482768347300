import ApiRoutes from "../utils/apiRoutes/routes";
import { Get, Put, Post } from "../utils/services/request2";


export const fetchPassList = async (params) => {
    try {
        const response = await Get(ApiRoutes.passes, null, params);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const fetchPassById = async (id) => {
    try {
        const response = await Get(`${ApiRoutes.passes}/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const updatePass = async (id,data) => {
    try {
        const response = await Put(`${ApiRoutes.passes}/${id}`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const deletePass = async (id) => {
    try {
        const response = await Put(`${ApiRoutes.passes}/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const createPass = async (data) => {
    try {
        const response = await Post(`${ApiRoutes.passes}/create`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}