const routeMap = {
    "add-category": "categories",
    "edit-category": "categories",
    "add-merchant": "merchants",
    "edit-merchant": "merchants",
    "add-pass": "passes",
    "edit-pass": "passes",
    "add-outlet": "outlets",
    "edit-outlet": "outlets",
    "add-amenities": "amenities",
    "edit-amenity": "amenities",
    "add-benefits": "benefits",
    "edit-benefit": "benefits",
    "add-user": "users"
  };
  export default routeMap