//
import React, { useEffect, useState, useCallback } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Table, Button, Space, Popconfirm, message, Switch, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { Get, Put } from "../../utils/services/request2";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formateDate } from "../../utils/functions/common";

const Benefits = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrorMsg] = useState("");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    getCategoryData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const getCategoryData = useCallback(async (page, limit) => {
    setLoading(true);
    try {
      const offset = (page - 1) * limit;
      const res = await Get(
        `${ApiRoutes.benefits}?offset=${offset}&limit=${limit}`
      );
      setTableData(res?.data?.data?.records || []);
      setTotal(res?.data?.data?.count || 0);
      setErrorMsg(
        res?.data?.data?.records?.length === 0 ? "No data found" : ""
      );
      setLoading(false);
    } catch (err) {
      setErrorMsg(err?.response?.data?.message || "Failed to fetch data");
      setTableData([]);
      setTotal(0);
      setLoading(false);
    }
  }, []);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    if (current !== currentPage || pageSize !== pageSize) {
      setCurrentPage(current);
      setPageSize(pageSize);
    }
  };

  const handleDelete = async (id) => {
    try {
      await Put(`${ApiRoutes.deleteBenefit}/${id}`);
      message.success("Benefits deleted successfully");
      getCategoryData(currentPage, pageSize);
    } catch (err) {
      message.error("Failed to delete benefits");
    }
  };

  const handleActiveDeactive = async (id, record) => {
    const data = {is_active: !record.is_active}
    try {
     const res = await Put(`${ApiRoutes.benefits}/${id}`, data);
      message.success(res?.data?.message);
      getCategoryData(currentPage, pageSize);
    } catch (err) {
      message.error('Failed to deactivated benefit');
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Arabic Name",
      dataIndex: "arabic_name",
      key: "arabicName",
      width: 200,
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 200,
    // },
    // {
    //   title: "Arabic Description",
    //   dataIndex: "arabic_description",
    //   key: "arabic_description",
    //   width: 200,
    // },
    {
      title: "Exclusion",
      dataIndex: "exclusion",
      key: "exclusion",
      width: 200,
    },
    {
      title: "Short Description",
      dataIndex: "short_description",
      key: "short_description",
      width: 200,
    },
    {
      title: "Terms",
      dataIndex: "terms",
      key: "terms",
      width: 200,
    },
    {
      title: "Estimated Savings",
      dataIndex: "estimated_savings",
      key: "estimated_savings",
      width: 200,
    },
    {
      title: "Benefit Count",
      dataIndex: "benefit_count",
      key: "benefit_count",
      width: 200,
    },
    {
      title: "Average Revenue",
      dataIndex: "average_revenue",
      key: "average_revenue",
      width: 200,
    },
    {
      title: "External ID",
      dataIndex: "external_id",
      key: "external_id",
      width: 200,
    },
    {
      title: "Benefit Value",
      dataIndex: "benefit_value",
      key: "benefit_value",
      width: 200,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => {
        return <span>{formateDate(record?.created_at)}</span>;
      },
      width: 200,
    },
    {
      title: "Passes",
      dataIndex: "passes",
      key: "passes",
      render: (text, record) => {
        return (
          <span>{record?.passes.map((item) => item?.name).join(", ")}</span>
        );
      },
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "active",
      render: (text, record) => {
        return  <Tag color="#c66238">{record?.is_active? "Activated": "Deactivated"}</Tag>
    },
      width: 120,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          {/* Edit Button */}
          <Button
            onClick={() => {
              navigate(`/edit-benefit/${record.id}`);
            }}
            style={{ border: 'none', backgroundColor: 'transparent' }}
            icon={<EditOutlined />}
          />

          <Popconfirm
            title={record.is_active ? "Are you sure you want to deactivate this benefit?" : "Are you sure you want to activate this benefit?"}
            onConfirm={() => handleActiveDeactive(record.id, record)} // Confirm delete action
            okText="Yes"
            cancelText="No"
          >

          <Switch checked={record.is_active} />

          </Popconfirm>
        </Space>
      ),
    },
  ];

  const redeem = () => {
    return (
      <div className="responsive-table-container">
        <div className="flex justify-end mb-2">
          <Button
            type="primary"
            onClick={() => {
              navigate("/add-benefits");
            }}
          >
            Add Benefits
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
          }}
          onChange={handleTableChange}
          locale={{ errMsg }}
          scroll={{ x: 600 }}
        />
      </div>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default Benefits;
