import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Button, Radio, Alert } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Popup from '../popup/popup';
import RedemptionPopUp from './redemptionPopup';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { Get, Post } from '../../utils/services/request2';
import Ipasscard from '../Ipasscard';
import { verifyUserData } from '../../utils/userData/userData';



const Redeem = () => {

  const [passIdPopup, setPassIdPopup] = useState(false)
  const [open, setOpen] = useState(false);
  const [errOpen, setErrOpen] = useState(false);
  const [outletBenefits, setOutletBenefits] = useState(null);
  const [passIdResponse, setPassIdResponse] = useState(null);
  const [selectedBenefits, setSelectedBenefits] = useState(null);
  const [loading, setLoading] = useState(null);
  const [err, setErr] = useState(null);
  const [errMsg, setErrMsg] = useState(null)
  const [redemptionMsg, setRedemptionMsg] = useState(null);
  const [redemptionCode, setRedemptionCode] = useState(null);


 useEffect(()=>{
  fetchoutletBenefits()
  const verify_user = localStorage.getItem("is_verify_user")
  if(!verify_user){
    setPassIdPopup(true)
  }else{
    setPassIdPopup(false)
  }
  setPassIdResponse(verifyUserData())
 },[])


  const fetchoutletBenefits = async () => {
    try {
      const res = await Get(ApiRoutes.ouletBenefits);
      setOutletBenefits(res?.data?.data)
    } catch (err) {
      
    }
  };

  const onChange = (e) => {
    setSelectedBenefits(e.target.value);
  };


  const redeemOffer = async() => {
      setLoading(true)
      const payload = {...passIdResponse, benefit_id: selectedBenefits}
      try {
        const res = await Post(ApiRoutes.redeemOffer, payload);
        setRedemptionMsg(res?.data?.message)
        setRedemptionCode(res?.data?.data?.redeem_code)
        setOpen(true)
        setLoading(false)
      } catch (err) {
        setErrOpen(true)
        setErr(true)
        setErrMsg(err.response?.data?.message)
        setLoading(false)
      }
   
 
  }

  return (
  <>
      <Popup
      err={true}
      open={errOpen}
      setOpen={()=> {
        setErrOpen(false)
        setPassIdPopup(true)
      }}
      modalSection={"Redemption"}
      info={errMsg}
      />
      <Popup
        open={open}
        setOpen={()=>{
          setOpen(false)
          setPassIdPopup(true)
        }}
        modalSection={"Redemption"}
        info={redemptionMsg}
        code={redemptionCode}
        />
      <RedemptionPopUp
        open={passIdPopup}
        getPassIdResponse={setPassIdResponse}
        setOpen={setPassIdPopup}
        modalSection={"Redemption"}
        info={"Please Enter Customer's Pass ID"}
        />
        
          {/* <CustomHeader type={"search"} /> */}
          {/* <Ipasscard name={passIdResponse?.full_name} id={passIdResponse?.pass_number} /> */}
          <div className='flex justify-between'>
                    <div>
                        <p className='mt-4'>BENEFITS</p>
                    </div>
                    <div>
                        <Button onClick={()=>{setPassIdPopup(true)}} type="primary" size="small" className='smallBtn'>
                            Change pass id
                        </Button>
                    </div>
                </div>
                <Radio.Group onChange={onChange} value={selectedBenefits} className='mt-6 w-full'>
                    <Space direction="vertical" className='w-full'>
                      {
                        outletBenefits?.map((el, i)=>{
                          return(
                            <>
                            <Radio key={i} value={el?.external_id}>{el?.name}</Radio>
                            {i < outletBenefits.length - 1 && <hr />}
                             </>
                          )
                        })
                      }
                    </Space>
                </Radio.Group>
                <Button loading={loading} onClick={()=> redeemOffer()}  disabled={!selectedBenefits || loading} style={{position: "fixed", bottom: "25px", left: 18}} type="primary" className="w-[90%]">REDEEM THIS OFFER</Button>
     
    </>
  )

};

export default Redeem;
