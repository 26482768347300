import React, { useEffect, useState } from 'react';
import Wrapper from '../../layouts/wrapper/wrapper';
import { Form, Input, Button, message, Spin } from 'antd';
import { Get, Put } from '../../utils/services/request2';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { useNavigate, useParams } from 'react-router-dom';

const EditAmenity = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    getAmenityById();
  }, []);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        name: formData.name,
        arabic_name: formData.arabic_name,
      });
    }
  }, [formData, form]);

  const getAmenityById = async () => {
    try {
      setLoading(true);
      const res = await Get(`${ApiRoutes.amenities}/${id}`);
      setFormData(res?.data?.data);
      setLoading(false);
    } catch (err) {
      message.error('Something went wrong!');
      setLoading(false);
    }
  };


  const handleSubmit = async (values) => {
    setLoading(true);

    const formDataToSubmit = {
      ...values,
    };

    try {
      const res = await Put(`${ApiRoutes.amenities}/${id}`, formDataToSubmit);
      setLoading(false);
      message.success(' updated successfully!');
      navigate('/amenities'); 
    } catch (err) {
      message.error('Something went wrong!');
      setLoading(false);
    }
  };

  const redeem = () => {
    return (
      <Spin spinning={loading}>
        <Form
          form={form}
          name="custom-form"
          layout="vertical"
          onFinish={handleSubmit}
          style={{ maxWidth: '600px', margin: '50px auto' }}
        >
          <Button className="mb-10" onClick={() => navigate('/amenities')} type="primary">
            Back
          </Button>
          
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please Enter Name!' }]}>
            <Input placeholder="Enter Name" />
          </Form.Item>

          <Form.Item
            label="Arabic Name"
            name="arabic_name"
          >
            <Input placeholder="Enter Arabic Name" />
          </Form.Item>

          <Form.Item>
            <Button className='w-full' disabled={loading} loading={loading} type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default EditAmenity;
