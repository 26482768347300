import axios from "axios";
import config from "../config";
import { getToken } from "../utils/userData/userData";
import service from "../utils/services/axiosBase2";

export const mediaUpload = async (file) => {
    try {
        let token = getToken();
        const formData = new FormData();
        formData.append("image", file);
        const response = await service.post("/api/v1/admin/media", formData, {
            headers: {
                Accept: "application/json",
                APP_VERSION: config.appVersion,
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response?.data;
    } catch (error) {
        throw error;
    }
}