// 
import React, { useEffect, useState, useCallback } from 'react';
import Wrapper from '../../layouts/wrapper/wrapper';
import { Form, Input, Button, message } from 'antd';
import { Post } from '../../utils/services/request2';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { useNavigate } from 'react-router-dom';


const AddAmenities = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)


  
    const handleSubmit = async (values) => {
        setLoading(true)
      const formData = {
        ...values,
      };  

      try {
        const res = await Post(ApiRoutes.createAmenity, formData);
        setLoading(false)
        message.success('Amenity added successfully!');
        form.resetFields();
      } catch (err) {
        message.error('something went wrong!');
        setLoading(false)
      }
   
    };


    const redeem = () => {
        return (
            <Form
            form={form}
            name="custom-form"
            layout="vertical"
            onFinish={handleSubmit}
            style={{ maxWidth: '600px', margin: '50px auto' }}
          >

             <Button className='mb-10' onClick={()=>{navigate("/amenities")}} type="primary">
                Back
              </Button>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please Enter Name!' }]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
      
            <Form.Item
              label="Arabic Name"
              name="arabic_name"
            >
              <Input placeholder="Enter Arabic Name" />
            </Form.Item>
     
            <Form.Item>
              <Button className='w-full' disabled={loading} loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
    }


    return (
        <Wrapper Comp={redeem} />
    );
}

export default AddAmenities;
