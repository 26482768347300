import React, { useState } from 'react';
import { Button, Modal, Form, Input, Alert, Spin, message } from 'antd';
import "./styles.scss"
import backIcon from '../../assets/images/back.png'
import nextIcon from "../../assets/images/next.png"
import ApiRoutes from '../../utils/apiRoutes/routes';
import { Get, Post } from '../../utils/services/request2';
import { Encoding } from '../../functions/base64';
import { Scanner } from '@yudiel/react-qr-scanner';
import { ScanOutlined, CloseCircleOutlined } from '@ant-design/icons';




const RedemptionPopUp = (props) => {
    const { open, setOpen, modalSection, info, code, getPassIdResponse } = props;

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [res, setRes] = useState(null);
    const [err, setError] = useState(null);
    const [errMsg, setErrorMsg] = useState(null)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isScannerVisible, setIsScannerVisible] = useState(false);


    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const params = {pin: values?.redemptionId}
            setLoading(true);
            try {
                const res = await Post(`${ApiRoutes.verifyQRCode}`, params);
                setLoading(false);
                setRes(res);
                getPassIdResponse(params);
                setOpen(false);
                setError(false);
                localStorage.setItem("is_verify_user", true);
                localStorage.setItem("verify_user_data", Encoding(JSON.stringify(params)));
            } catch (err) {
                setLoading(false);
                setError(true);
                setErrorMsg(err?.response?.data?.message);
            }

        } catch (info) {
            console.log('Validate Failed:', info);
        }
    };


    const verfiyQR = async(params) => {
        try {
            const res = await Post(`${ApiRoutes.verifyQRCode}`, params);
            getPassIdResponse(params);
            setOpen(false);
            setError(false);
            localStorage.setItem("is_verify_user", true);
            localStorage.setItem("verify_user_data", Encoding(JSON.stringify(params)));
        } catch (err) {
            message.error(err?.response?.data?.message);
        }
    }

    

    const handleScan = (result) => {
        const scanned_code = decodeURIComponent(result[0].rawValue);
        verfiyQR({url: scanned_code})
        setIsScannerVisible(false); // Close the scanner
    };

    // Function to open the scanner on clicking the Scan icon
    const openScanner = () => {
        setIsScannerVisible(true);
    };



    const handleResize = () => {
        setIsMobile(window.innerWidth < 768); // Update isMobile state when window resizes
    };

    // Add event listener for window resize
    React.useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Modal
                className="full-screen-modal text-center"
                open={open}
                title={<h1 className='mb-4 text-start'>{modalSection.toUpperCase()}</h1>}
                onOk={handleOk}
                onCancel={handleOk}
                closable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                footer={null}
            >
                {/* <img onClick={handleOk} className='backBtn' style={{ position:"fixed", top: 10, left: 0}} src={backIcon} /> */}
                <Form
                    form={form}
                    name="redemption_form"
                // initialValues={{ remember: true, redemptionId: "123" }}
                >
                    {err && (
                        <Alert
                            type="error"
                            message={errMsg}
                            className="mb-4"
                        />
                    )}
                    {isMobile ? <>
                        {isScannerVisible && (
                            <>
                            <Scanner classNames="scanner-section" onScan={handleScan} />
                            <CloseCircleOutlined onClick={()=> setIsScannerVisible(false)}  className="scanner-close" />
                            </>
                        )}
                  {!isScannerVisible && <div>
                        <p className='mt-3 text-left'>Please enter customer's pass id</p>
                        <div>
                            <div className="flex mt-3">
                                <div className="w-3/4">
                                    <Form.Item
                                        name="redemptionId"
                                        rules={[{ required: true, message: "Please enter the customer's Pass ID!" }]}
                                    >
                                        <Input placeholder="Please enter customer's Pass ID" inputMode="numeric" pattern="[0-9]*" />

                                    </Form.Item>
                                </div>
                                {loading ? <div className='ml-6 mt-2'><Spin /></div> : <div className="w-1/4 flex justify-end">
                                    <img type="primary" onClick={handleOk} className="nextIcon" src={nextIcon} />
                                </div>}
                            </div>
                            <p>OR</p>
                            <div>
                                <ScanOutlined onClick={openScanner} className='scanner-icon'/>

                            </div>
                        </div>
                        </div>}
                    </> :
                        <>
                         {!isScannerVisible && <div>
                            <Form.Item
                                name="redemptionId"
                                rules={[{ required: true, message: "Please Enter the Customer's Pass ID!" }]}
                            >
                                <Input type="number" placeholder="Please Enter Customer's Pass ID" />
                            </Form.Item>

                            <Form.Item>
                                <Button loading={loading}
                                    disabled={loading} className='okBtn' type="primary" onClick={handleOk} >
                                    Ok
                                </Button>
                            </Form.Item>
                            <p>OR</p>
                            <div style={{fontSize: "200px"}}>
                                <ScanOutlined onClick={openScanner} className='scanner-icon'/>

                            </div>
                            </div>}
                            {isScannerVisible && (
                            <>
                            <Scanner classNames="scanner-section" onScan={handleScan} />
                            <CloseCircleOutlined onClick={()=> setIsScannerVisible(false)}  className="scanner-close" />
                            </>
                        )}
                        </>
                    }
                </Form>
            </Modal>
        </>
    );
};

export default RedemptionPopUp;
