import axios from "axios";
import config from "../../config";
import { getToken, refreshToken } from "../userData/userData";
import ApiRoutes from "../apiRoutes/routes";

const baseURL = config.baseURL2;
const baseURLUm = config.baseURL;

const service = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    APP_VERSION: config.appVersion,
  },
});

service.interceptors.request.use((req) => {
  let token = getToken()
  if (token) {
    let headers = { ...req.headers };
    req.headers = {
      Authorization: `Bearer ${token}`,
      ...headers,
    };
  } else {
    let headers = { ...req.headers };
    req.headers = {
      ...headers,
    };
  }
  return req;
});

service.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (error) => {
    const originalRequest = error.config;
    const status = error?.response?.status;
    if (status === 401) {
        window.location.href = "/login"
        localStorage.clear()
      // originalRequest._retry = true;
      // try {

      //   // Call the refreshToken API to get a new token
      //   const refreshTokenResponse = await axios.post(baseURLUm + ApiRoutes.refreshToken, {
      //     refreshToken: refreshToken()
      //   });
      //   const newToken = refreshTokenResponse.data.token;
      //   // Store the new token
      //   localStorage.setItem('access_token', newToken);

      //   // Set the Authorization header with the new token
      //   originalRequest.headers['Authorization'] = 'Bearer ' + newToken;

      //   // Retry the original request with the new token
      //   return service(originalRequest);
      // } catch (refreshError) {
      //   // Handle the refresh token error
      //   window.location.href = "/login"
      //   localStorage.clear()
      //   return Promise.reject(refreshError);
      // }
    } else if (status === 502 || status === 503) {
      window.location.href = "/underMaintenance"
    } else {
      return Promise.reject(error);
    }
}
);

export default service;
