// 
import React, { useEffect, useState, useCallback } from 'react';
import Wrapper from '../../layouts/wrapper/wrapper';
import { Table, Button } from 'antd';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { Get } from '../../utils/services/request2';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


const Users = () => {

    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrorMsg] = useState("");
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    useEffect(() => {
      getUserData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const getUserData = useCallback(async (page, limit) => {
      setLoading(true);
      try {
          const offset = (page - 1) * limit;
          const res = await Get(`${ApiRoutes.userListing}?offset=${offset}&limit=${limit}`);
          setTableData(res?.data?.data?.records || []);
          setTotal(res?.data?.data?.count || 0);
          setErrorMsg(res?.data?.data?.records?.length === 0 ? 'No data found' : '');
          setLoading(false);
      } catch (err) {
          setErrorMsg(err?.response?.data?.message || 'Failed to fetch data');
          setTableData([]);
          setTotal(0);
          setLoading(false)
          
      } 
  }, []);


  const handleTableChange = (pagination) => {
      const { current, pageSize } = pagination;
      if (current !== currentPage || pageSize !== pageSize) {
          setCurrentPage(current);
          setPageSize(pageSize);
      }
  };


    const columns = [
        {
          title: 'Full Name',
          dataIndex: 'full_name',
          key: 'full_name',
        },

        {
          title: 'Email',
          dataIndex: ['user_login', 'email'],
          key: 'email',
        },
    
        {
          title: 'Mobile',
          dataIndex: ['user_login', 'mobile'],
          key: 'mobile',
        },

        {
          title: 'Status',
          dataIndex: "is_active",
          key: 'is_active',
          render: (isActive) => (isActive ? 'Yes' : 'No'),
        },
  
  
        {
          title: 'outlet_name',
          dataIndex: ['outlet_users', 'outlet_name'],
          key: 'outlet_name',
        },
  
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'), // Format the date
          }
      ];
    



    const redeem = () => {
        return (
            <div className="responsive-table-container">
                <div className='flex justify-end mb-2'>
            <Button type='primary' onClick={()=>{ navigate('/add-user')}}>Add User</Button>
            </div>
            <Table
              columns={columns}
              dataSource={tableData}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: total,
              }}
              onChange={handleTableChange}
              locale={{ errMsg }}
              scroll={{ x: 600 }} 
            />
          </div>
        )
    }


    return (
        <Wrapper Comp={redeem} />
    );
}

export default Users;
