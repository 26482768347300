import React, { createContext, useState, useContext, useEffect } from 'react'
import { ConfigProvider } from 'antd';
import moment from 'moment';


export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });


  const login = () => setIsAuthenticated(true);
  const logout = () => setIsAuthenticated(false);

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#C66238',
      },
    }}
    datePicker={{
      locale: moment.locale(), // Use moment's locale configuration
      generateConfig: moment,  // Use moment for date operations
    }}
  >

    <AuthContext.Provider value={{ isAuthenticated, login, logout  }}>
      {children}
    </AuthContext.Provider>
    </ConfigProvider>
  )
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext);




