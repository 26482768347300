//
import React, { useEffect, useState, useCallback } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Form, Input, Button, message, Select } from "antd";
import MultiImageUploader from "../../assets/hooks/multiImagesUploder";
import DynamicMediaUploader from "../../assets/hooks/multiImagesUploder";
import { Get, Post } from "../../utils/services/request2";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { useNavigate } from "react-router-dom";
import { fetchCategoryList } from "../../apiFunctions/categories";
import { fetchOutletList } from "../../apiFunctions/outlets";
import { fetchPassList } from "../../apiFunctions/passes";
import { preventToEnterSymbol, validateCategories } from "../../utils/functions/common";
import moment from "moment";
import DatePicker from 'react-date-picker';
import CustomAutoComplete from "../../components/inputs/autocomplete";

const AddBenefits = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = {
      ...values,
      media: fileList?.map(v => ({ path: v?.url, size: v?.size ?? 1 })),
      end_date: moment(new Date(values.end_date)).format("YYYY-MM-DD"),
      estimated_savings: Number(values.estimated_savings),
      benefit_count: Number(values.benefit_count),
      average_revenue: Number(values.average_revenue),
      benefit_value: Number(values.benefit_value),
      other_primary_category: values?.other_primary_category ?? [],
      category_id: values.category_id ?? [],
    };
    const errors = validateCategories(formData)
    console.log("Error", errors)
    if (!errors) {
      try {
        const res = await Post(ApiRoutes.createBenefit, formData);
        setLoading(false);
        message.success("Benefits added successfully!");
        form.resetFields();
        setFileList([]);
      } catch (err) {
        message.error("something went wrong!");
        setLoading(false);
      }
    } else {
      if (errors) {
        errors?.forEach(([field1, field2]) => {
          form.setFields([
            {
              name: field1,
              errors: ["This field has a conflict with another field."],
            },
            {
              name: field2,
              errors: ["This field has a conflict with another field."],
            },
          ]);
        });
        setLoading(false);
        return;
      }
    }
  };

  const getUploadedList = (list) => {
    console.log(list, "imtiaz");
  };


  const fetchOutlets = async (value) => {
    try {
      const outlets = await fetchOutletList(value ? { name: value } : {});
      return outlets?.data?.records?.map(v => ({ label: v?.name, value: v?.id })) ?? []
    } catch (error) {
      return [];
    }
  }

  const fetchCategories = async (value) => {
    try {
      const categories = await fetchCategoryList(value ? { name: value } : {});
      return categories?.data?.records?.map(v => ({ label: v?.name, value: v?.id })) ?? []
    } catch (error) {
      return [];
    }
  }

  const fetchAmenities = async (value) => {
    try {
      const amenities = await Get(`${ApiRoutes.amenities}${value ? `?name=${value}` : ''}`);
      return amenities?.data?.data?.records?.map(v => ({ label: v?.name, value: v?.id })) ?? []
    } catch (error) {
      return [];
    }
  }

  const fetchPasses = async (value) => {
    try {
      const passes = await fetchPassList(value ? { name: value } : {});
      return passes?.data?.records?.map(v => ({ label: v?.name, value: v?.id })) ?? []
    } catch (error) {
      return [];
    }
  }


  const redeem = () => {
    return (
      <Form
        form={form}
        name="custom-form"
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: "600px", margin: "50px auto" }}
      >
        <Button
          className="mb-10"
          onClick={() => {
            navigate("/benefits");
          }}
          type="primary"
        >
          Back
        </Button>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please Enter Name" }]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          label="Arabic Name"
          name="arabic_name"
        >
          <Input placeholder="Enter Arabic Name" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
        >
          <Input placeholder="Enter Description" />
        </Form.Item>

        <Form.Item
          label="Arabic Description"
          name="arabic_description"
        >
          <Input placeholder="Enter Arabic Description" />
        </Form.Item>

        <Form.Item
          label="Exclusion"
          name="exclusion"
        >
          <Input placeholder="Enter Exclusion" />
        </Form.Item>

        <Form.Item
          label="Arabic Exclusion"
          name="arabic_exclusion"
        >
          <Input placeholder="Enter Arabic Exclusion" />
        </Form.Item>

        <Form.Item
          label="Short Description"
          name="short_description"
        >
          <Input placeholder="Enter Short Description" />
        </Form.Item>

        <Form.Item
          label="Short Arabic Description"
          name="short_arabic_description"
        >
          <Input placeholder="Enter Short Arabic Description" />
        </Form.Item>

        <Form.Item
          label="Terms"
          name="terms"
        >
          <Input placeholder="Enter Terms" />
        </Form.Item>

        <Form.Item
          label="Estimated Savings"
          name="estimated_savings"
        >
          <Input type="number" onKeyDown={preventToEnterSymbol} min={0} placeholder="Enter Estimated Savings" />
        </Form.Item>

        <Form.Item
          label="Benefit Count"
          name="benefit_count"
          rules={[{ required: true, message: "Please Enter Benefit Count!" }]}
        >
          <Input type="number" onKeyDown={preventToEnterSymbol} min={0} placeholder="Enter Benefit Count" />
        </Form.Item>

        <Form.Item
          label="Average Revenue"
          name="average_revenue"
        >
          <Input type="number" onKeyDown={preventToEnterSymbol} min={0} placeholder="Enter Average Revenue" />
        </Form.Item>

        <Form.Item
          label="Benefit Value"
          name="benefit_value"
          rules={[{ required: true, message: "Please Enter Benefit Value!" }]}
        >
          <Input type="number" onKeyDown={preventToEnterSymbol} min={0} placeholder="Enter Benefit Value" />
        </Form.Item>

        <Form.Item
          label="End Date"
          name="end_date"
          rules={[{ required: true, message: "Please Enter End Date!" }]}
        >
          <DatePicker className="w-full" />
        </Form.Item>

        <Form.Item
          label="Is Unlimited"
          name="is_unlimited"
          rules={[{ required: true, message: "Please Select Is Unlimited!" }]}
        >
          <Select
            showSearch
            className='antd__inp'
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            placeholder="Is Unlimited"
            filterOption={(input, option) =>
              (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          label="Primary Category"
          name="primary_category"
          rules={[{ required: true, message: "Please Select Primary Category!" }]}
        >
          <CustomAutoComplete placeholder={"Select Primary Category"} name={'primary_category'} fetchData={fetchCategories} form={form} />
        </Form.Item>

        <Form.Item
          label="Category"
          name="category_id"
        >
          <CustomAutoComplete multiple placeholder={"Select Category"} name={'category_id'} fetchData={fetchCategories} form={form} />
        </Form.Item>

        <Form.Item
          label="Other Primary Category"
          name="other_primary_category"
        >
          <CustomAutoComplete multiple placeholder={"Select Other Primary Category"} name={'other_primary_category'} fetchData={fetchCategories} form={form} />
        </Form.Item>

        <Form.Item
          label="Amenity"
          name="amenity_id"
          rules={[{ required: true, message: "Please Select Amenity!" }]}
        >
          <CustomAutoComplete
            multiple
            placeholder={"Select Amenity"}
            name={'amenity_id'}
            fetchData={fetchAmenities}
            form={form}
          />
        </Form.Item>

        <Form.Item
          label="Pass"
          name="pass_id"
          rules={[{ required: true, message: "Please Select Pass!" }]}
        >
          <CustomAutoComplete multiple placeholder={"Select Pass"} name={'pass_id'} fetchData={fetchPasses} form={form} />
        </Form.Item>

        <Form.Item
          label="Outlet"
          name="outlet_id"
          rules={[{ required: true, message: "Please Select Outlet!" }]}
        >
          <CustomAutoComplete multiple placeholder={"Select Outlet"} name={'outlet_id'} fetchData={fetchOutlets} form={form} />
        </Form.Item>

        <Form.Item label="Media Files">
          <DynamicMediaUploader setFileList={setFileList} fileList={fileList} />
        </Form.Item>
        <Form.Item>
          <Button
            className="w-full"
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default AddBenefits;
