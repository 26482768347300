export const offlineIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="115"
      height="59"
      viewBox="0 0 115 59"
    >
      <g id="Group_2773" data-name="Group 2773" transform="translate(-122 -164)">
        <path
          id="Union_1"
          data-name="Union 1"
          d="M-1183.293-1106l.214-.006a15.41,15.41,0,0,0,10.677-4.673,15.415,15.415,0,0,0,4.4-10.821,15.4,15.4,0,0,0-4.54-10.961,15.4,15.4,0,0,0-10.96-4.54,15.548,15.548,0,0,0-4.037.53l-2.575.692-.99-2.475a15.494,15.494,0,0,0-5.61-7.018,15.406,15.406,0,0,0-8.787-2.73,15.69,15.69,0,0,0-2.389.183l-1.727.267-1.084-1.371a26.764,26.764,0,0,0-9.06-7.343A26.23,26.23,0,0,0-1231.5-1159a26.441,26.441,0,0,0-8.791,1.492,26.392,26.392,0,0,0-7.543,4.139,26.6,26.6,0,0,0-5.792,6.28,26.309,26.309,0,0,0-3.523,7.9l-.632,2.43-2.5-.194c-.4-.031-.811-.047-1.216-.047a15.4,15.4,0,0,0-10.96,4.54A15.4,15.4,0,0,0-1277-1121.5a15.415,15.415,0,0,0,4.4,10.821,15.411,15.411,0,0,0,10.677,4.673l.214.006h78.414m.293,3h-79v-.007a18.5,18.5,0,0,1-18-18.493,18.5,18.5,0,0,1,18.5-18.5q.731,0,1.448.056A29.512,29.512,0,0,1-1231.5-1162a29.447,29.447,0,0,1,23.153,11.218,18.636,18.636,0,0,1,2.847-.218,18.506,18.506,0,0,1,17.183,11.633,18.52,18.52,0,0,1,4.817-.633,18.5,18.5,0,0,1,18.5,18.5,18.5,18.5,0,0,1-18,18.493Z"
          transform="translate(1402 1326)"
          fill="#fff"
        />
        <g
          id="Group_2773-2"
          data-name="Group 2773"
          transform="translate(165.621 186.489)"
        >
          <path
            id="Union_2"
            data-name="Union 2"
            d="M7.612-1.5a1.5,1.5,0,0,1,1.061.439A1.5,1.5,0,0,1,9.112,0a1.5,1.5,0,0,1-.439,1.061L5.928,3.806,8.673,6.552A1.5,1.5,0,0,1,6.552,8.673L3.806,5.927,1.061,8.673a1.5,1.5,0,0,1-2.121,0A1.5,1.5,0,0,1-1.5,7.612a1.5,1.5,0,0,1,.439-1.061L1.685,3.806-1.061,1.061A1.5,1.5,0,0,1-1.5,0a1.5,1.5,0,0,1,.439-1.061,1.5,1.5,0,0,1,2.121,0L3.806,1.685,6.552-1.061A1.5,1.5,0,0,1,7.612-1.5Z"
            fill="#fff"
          />
          <path
            id="Union_3"
            data-name="Union 3"
            d="M7.612-1.5a1.5,1.5,0,0,1,1.061.439A1.5,1.5,0,0,1,9.112,0a1.5,1.5,0,0,1-.439,1.061L5.928,3.806,8.673,6.552A1.5,1.5,0,0,1,6.552,8.673L3.806,5.927,1.061,8.673a1.5,1.5,0,0,1-2.121,0A1.5,1.5,0,0,1-1.5,7.612a1.5,1.5,0,0,1,.439-1.061L1.685,3.806-1.061,1.061A1.5,1.5,0,0,1-1.5,0a1.5,1.5,0,0,1,.439-1.061,1.5,1.5,0,0,1,2.121,0L3.806,1.685,6.552-1.061A1.5,1.5,0,0,1,7.612-1.5Z"
            transform="translate(21.145)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_2774"
          data-name="Group 2774"
          transform="translate(170.936 201.553)"
        >
          <path
            id="Path_1122"
            data-name="Path 1122"
            d="M1461,1304.936v-.012l-3-.1a8.544,8.544,0,0,1,1.438-4.165c1.235-1.846,3.778-4.046,8.885-4.046a1.5,1.5,0,0,1,0,3c-2.978,0-5.105.879-6.323,2.613A5.741,5.741,0,0,0,1461,1304.936Z"
            transform="translate(-1459.5 -1298.116)"
            fill="#fff"
          />
          <path
            id="Path_1123"
            data-name="Path 1123"
            d="M1466.825,1304.936a5.742,5.742,0,0,0-1-2.707c-1.217-1.734-3.345-2.613-6.323-2.613a1.5,1.5,0,0,1,0-3c5.107,0,7.651,2.2,8.885,4.046a8.545,8.545,0,0,1,1.438,4.165l-3,.1Z"
            transform="translate(-1451.042 -1298.116)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
  