import "./App.scss";
import AuthProvider from "./context";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login/login";
import Home from "./pages/home/home";
import ProtectedRoute from "./router/protectedRoute";
import Redemption from "./pages/redemption/redemption";
import InternetConnection from "./components/noConnection/noConnection";
import PageNotFound from "./components/404/404";
import UnderMaintenance from "./components/underMaintenance/underMaintenance";
import OutletRedemption from "./pages/outletRedemption/outletRedemption";
import {
  PartnerList,
  PartnerCreate,
  PartnerUpdate,
} from "./pages/admin";
import Categories from "./pages/categories/categories";
import AddCategories from "./pages/categories/addCategories";
import EditCategories from "./pages/categories/editCategories";
import Merchants from "./pages/merchants/merchants";
import CreateMerchant from "./pages/merchants/createMerchant";
import EditMerchant from "./pages/merchants/editMerchant";
import Amenities from "./pages/amenities/amenities";
import AddAmenities from "./pages/amenities/addAmenities";
import EditAmenity from "./pages/amenities/editAmenities";
import Benefits from "./pages/benefits/benefits";
import AddBenefits from "./pages/benefits/addBenefits";
import EditBenefits from "./pages/benefits/editBenefits";
import EditOutlet from "./pages/outlets/editOutlet";
import Outlets from "./pages/outlets/outlets";
import AddOutlet from "./pages/outlets/addOutlet";
import Passes from "./pages/passes/passes";
import AddPass from "./pages/passes/addPass";
import EditPass from "./pages/passes/editPass";
import Users from "./pages/users/users";
import AddUser from "./pages/users/addUser";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


function App() {
  return (
    <InternetConnection>
      <Router>
        <AuthProvider>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/redeem" element={<Redemption />} />
              <Route path="/*" element={<PageNotFound />} />
              <Route path="/underMaintenance" element={<UnderMaintenance />} />
              <Route path="/redemption" element={<OutletRedemption />} />
              <Route path="/passes" element={<Passes />} />
              <Route path="/add-pass" element={<AddPass />} />
              <Route path="/edit-pass/:id" element={<EditPass />} />
              <Route path="/partner" element={<PartnerList />} />
              <Route path="/add-partner" element={<PartnerCreate />} />
              <Route
                path="/edit-partner/:id"
                element={<PartnerUpdate />}
              />
              <Route path="/benefits" element={<Benefits />} />
              <Route path="/add-benefits" element={<AddBenefits />} />
              <Route
                path="/edit-benefit/:id"
                element={<EditBenefits />}
              />
              <Route path="/amenities" element={<Amenities />} />
              <Route
                path="/add-amenities"
                element={<AddAmenities />}
              />
              <Route
                path="/edit-amenity/:id"
                element={<EditAmenity />}
              />
              <Route path="/admin/categories/list" element={<Categories />} />
              <Route
                path="/admin/categories/create"
                element={<AddCategories />}
              />
              <Route
                path="/admin/categories/update/:id"
                element={<EditCategories />}
              />
              <Route path="/outlets" element={<Outlets />} />
              <Route path="/add-outlet" element={<AddOutlet />} />
              <Route
                path="/edit-outlet/:id"
                element={<EditOutlet />}
              />
              <Route path="/merchants" element={<Merchants />} />
              <Route path="/add-merchant" element={<CreateMerchant />} />
              <Route path="/edit-merchant/:id" element={<EditMerchant />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/add-category" element={<AddCategories />} />
              <Route path="/edit-category/:id" element={<EditCategories />} />
              <Route path="/users" element={<Users />} />
              <Route path="/add-user" element={<AddUser />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </InternetConnection>
  );
}

export default App;
