import React, { useEffect, useState } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Form, Input, Button, message, Spin } from "antd";
import DynamicMediaUploader from "../../assets/hooks/multiImagesUploder";
import { Get, Put } from "../../utils/services/request2";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { useNavigate, useParams } from "react-router-dom";

const EditCategories = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    getCategoryByID();
  }, []);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        name: formData.name,
        arabic_name: formData.arabic_name,
      });

      if (formData?.medias && formData?.medias?.length > 0) {
        const initialFileList = formData?.medias?.map((file, index) => ({
          id: file?.id, // Unique identifier for each file
          url: file.path, // URL for the file preview
          size:file?.size
        }));
        setFileList(initialFileList);
      }
    }
  }, [formData, form]);

  const getCategoryByID = async () => {
    try {
      setLoading(true);
      const res = await Get(`${ApiRoutes.categoryListing}/${id}`);
      setFormData(res?.data?.data);
      setLoading(false);
    } catch (err) {
      message.error("Something went wrong!");
      setLoading(false);
    }
  };

  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const formDataToSubmit = {
      ...values,
      media: fileList?.map(v => (v?.id ? { path: v?.url, size: v?.size ?? 1, id: v?.id } : { path: v?.url,size: v?.size ?? 1, })),
    };

    try {
      const res = await Put(
        `${ApiRoutes.categoryListing}/${id}`,
        formDataToSubmit
      );
      setLoading(false);
      message.success("Category updated successfully!");
      form.resetFields();
      setFileList([]);
      navigate("/categories");
    } catch (err) {
      message.error("Something went wrong!");
      setLoading(false);
    }
  };

  const getUploadedList = (list) => {
    console.log(list, "Uploaded Files");
  };

  const redeem = () => {
    return (
      <Spin spinning={loading}>
        <Form
          form={form}
          name="custom-form"
          layout="vertical"
          onFinish={handleSubmit}
          style={{ maxWidth: "600px", margin: "50px auto" }}
        >
          <Button
            className="mb-10"
            onClick={() => navigate("/categories")}
            type="primary"
          >
            Back
          </Button>

          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please Enter Name!" }]}
          >
            <Input placeholder="Enter Name" />
          </Form.Item>

          <Form.Item
            label="Arabic Name"
            name="arabic_name"
          >
            <Input placeholder="Enter Arabic Name" />
          </Form.Item>

          <Form.Item label="Media Files">
            <DynamicMediaUploader
              fileList={fileList}
              setFileList={handleFileChange}
              uploadedList={getUploadedList}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="w-full"
              disabled={loading}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default EditCategories;
