import React from 'react';
import { Button, Modal } from 'antd';
const Popup = (props) => {
    const { open, setOpen, modalSection, info, code, err } = props;
    const handleOk = () => {
        setOpen(false);
    };
    return (
        <>
            <Modal className='text-center' open={open}   title={<h3>{modalSection.toUpperCase()}</h3>}  onOk={handleOk} onCancel={handleOk} closable={false} cancelButtonProps={{ style: { display: 'none' } }} footer={[
                <Button key="ok" type="primary" onClick={handleOk} className='w-full'>
                    Done
                </Button>,
            ]}>
                 <div className="modal-content">
                <div className='text-center p-6'>
                        <p className='py-2' style={err && {color: "red", fontWeight: "bold"}}>{info}</p>
                       <p>{code}</p> 
                </div>
                </div>
            </Modal>
        </>
    );
};
export default Popup;