import { Decoding } from "../../functions/base64";

export function getUserData(){
  let data;
  if (localStorage?.getItem("userData")) {
    data = JSON.parse(Decoding(localStorage?.getItem("userData")) || "")
  }
  return data
}

export function getToken() {
let token;
if (localStorage?.getItem("access_token")) {
    token = JSON.parse(Decoding(localStorage?.getItem("access_token"))) || ""
  }
  return token
}


export function refreshToken(){
  let refreshTok;
  if (localStorage?.getItem("refreshToken")) {
    refreshTok = JSON.parse(Decoding(localStorage?.getItem("refreshToken"))) || ""
  }
  return refreshTok
}


export function verifyUserData(){
  let data;
  if (localStorage?.getItem("verify_user_data")) {
    data = JSON.parse(Decoding(localStorage?.getItem("verify_user_data")) || "")
  }
  return data
}


export function userType(){
  let data;
  if (localStorage?.getItem("userType")) {
    data = JSON.parse(Decoding(localStorage?.getItem("userType")) || "")
  }
  return data
}