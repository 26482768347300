// 
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../layouts/container/container';
import Wrapper from '../../layouts/wrapper/wrapper';
import Ipasscard from '../../components/Ipasscard';
import { Radio, Space, Button } from 'antd';


const Redemption = () => {

    const [value, setValue] = useState(1);


    useEffect(() => {

    }, []);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const redeem = () => {
        return (
            <>
                <Ipasscard />
                <p className='mt-4'>BENEFITS</p>
                <Radio.Group onChange={onChange} value={value} className='mt-6 w-full'>
                    <Space direction="vertical" className='w-full'>
                        <Radio value={1}>Free 3-day Gym Day Pass</Radio>
                        <hr />
                        <Radio value={2}>30% Off annual Gym membership</Radio>
                        <hr />
                        <Radio value={3}>50% Off workout classes</Radio>
                    </Space>
                </Radio.Group>
                <Button style={{position: "absolute", bottom: "25px", left: 18}} type="primary" className="w-[90%]">REDEEM THIS OFFER</Button>
            </>
        )
    }


    return (
        <Wrapper Comp={redeem} backBtn={true} />
    );
}

export default Redemption;
