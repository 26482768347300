import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload, message } from 'antd';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { Post } from '../../utils/services/request2';
import { mediaUpload } from '../../apiFunctions/mediaUpload';
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const DynamicMediaUploader = ({ setFileList, fileList }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [imgLoading, setImgLoading] = useState(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };


  const handleChange = async (info) => {
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === 'image/jpg' || info.file.type === "image/png";
    if (isJpgOrPng) {
      const response = await mediaUpload(info.file.originFileObj);
      setFileList(files => [...files,{url:response?.data?.file,uid:response?.data?.file}]);
      // const formData = new FormData();
      // formData.append("image", info.file.originFileObj);
      // setImgLoading(true);
      // const res = await Post(ApiRoutes.imgUpload, formData);
      // if (res?.status === 200) {
      //   setImgLoading(false);
      // } else {
      //   setImgLoading(false);
      //   message.info("Something went wrong");
      // }
    }
  };

  // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);


  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <>
      <Upload
        customRequest={({ onSuccess }) =>
          setTimeout(() => {
            onSuccess("ok", null);
          }, 0)
        }
        // action={"https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={(file) => setFileList(fileList.filter((item) => item.uid !== file.uid))}
      >
        {fileList?.length >= 8 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrDynamicMediaUploadererStyle={{
            display: 'none',
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};
export default DynamicMediaUploader;