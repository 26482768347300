//
import React, { useEffect, useState, useCallback } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Table, Button, Space, Popconfirm, message, Switch, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { Get, Put } from "../../utils/services/request2";
import { useNavigate } from "react-router-dom";

const Outlets = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrorMsg] = useState("");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    getCategoryData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const getCategoryData = useCallback(async (page, limit) => {
    setLoading(true);
    try {
      const offset = (page - 1) * limit;
      const res = await Get(
        `${ApiRoutes.Outlet}?offset=${offset}&limit=${limit}`
      );
      setTableData(res?.data?.data?.records || []);
      setTotal(res?.data?.data?.count || 0);
      setErrorMsg(
        res?.data?.data?.records?.length === 0 ? "No data found" : ""
      );
      setLoading(false);
    } catch (err) {
      setErrorMsg(err?.response?.data?.message || "Failed to fetch data");
      setTableData([]);
      setTotal(0);
      setLoading(false);
    }
  }, []);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    if (current !== currentPage || pageSize !== pageSize) {
      setCurrentPage(current);
      setPageSize(pageSize);
    }
  };

  const handleDelete = async (id) => {
    try {
      await Put(`${ApiRoutes.deleteOutlet}/${id}`);
      message.success("Outlet deleted successfully");
      getCategoryData(currentPage, pageSize);
    } catch (err) {
      message.error("Failed to delete Outlet");
    }
  };

  const handleActiveDeactive = async (id, record) => {
    const data = {is_active: !record.is_active}
    try {
     const res = await Put(`${ApiRoutes.Outlet}/${id}`, data);
      message.success(res?.data?.message);
      getCategoryData(currentPage, pageSize);
    } catch (err) {
      message.error('Failed to deactivated outlet');
    }
  };

const columns =  [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200
    },
    {
        title: 'Arabic Name',
        dataIndex: 'arabic_name',
        key: 'arabicName',
        width: 200
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: 200
    },
    {
        title: 'Arabic Address',
        dataIndex: 'arabic_address',
        key: 'arabic_address',
        width: 200
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        width: 200
    },
    {
        title: 'Phone 2',
        dataIndex: 'phone_2',
        key: 'phone_2',
        width: 200
    },
    {
        title: 'Phone 3',
        dataIndex: 'phone_3',
        key: 'phone_3',
        width: 200
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200
    },
    {
        title: 'Latitude',
        dataIndex: 'lat',
        key: 'lat',
        width: 200
    },
    {
        title: 'Longitude',
        dataIndex: 'lng',
        key: 'lng',
        width: 200
    },
    {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        width: 200,
        render: (text, record) => {
          return  <Tag color="#c66238">{record?.is_active? "Activated": "Deactivated"}</Tag>
      },
    },
    {
        title: 'Is Busy',
        dataIndex: 'is_busy',
        key: 'is_busy',
        width: 200,
        render: (text) => text ? "Busy" : "Not Busy"
    },
    {
        title: 'Operating Hours',
        dataIndex: 'operating_hours',
        key: 'operating_hours',
        width: 200
    },
    {
        title: 'Redemption Email',
        dataIndex: 'redemption_email',
        key: 'redemption_email',
        width: 200
    },
    {
        title: 'City ID',
        dataIndex: 'city_id',
        key: 'city_id',
        width: 200
    },
    {
        title: 'Area ID',
        dataIndex: 'area_id',
        key: 'area_id',
        width: 200
    },
    {
        title: 'Landmark ID',
        dataIndex: 'landmark_id',
        key: 'landmark_id',
        width: 200
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          {/* Edit Button */}
          <Button
            onClick={() => {
              navigate(`/edit-outlet/${record.id}`);
            }}
            style={{ border: 'none', backgroundColor: 'transparent' }}
            icon={<EditOutlined />}
          />

          <Popconfirm
            title={record.is_active ? "Are you sure you want to deactivate this outlet?" : "Are you sure you want to activate this outlet?"}
            onConfirm={() => handleActiveDeactive(record.id, record)} // Confirm delete action
            okText="Yes"
            cancelText="No"
          >

          <Switch checked={record.is_active} />

          </Popconfirm>
        </Space>
      ),
    },
]

  const redeem = () => {
    return (
      <div className="responsive-table-container">
        <div className="flex justify-end mb-2">
          <Button
            type="primary"
            onClick={() => {
              navigate("/add-outlet");
            }}
          >
            Add Outlet
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
          }}
          onChange={handleTableChange}
          locale={{ errMsg }}
          scroll={{ x: 600 }}
        />
      </div>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default Outlets;
