export const breakdowns = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
    xl: 8,
    xxl: 8
}

export const CURRENCY_LIST = [
    {
      value: 1,
      label: 'QAR'
    },
    {
      value: 2,
      label: 'PKR'
    },
    {
      value: 3,
      label: 'INR'
    },
    {
      value: 4,
      label: 'USD'
    },
    {
      value: 5,
      label: 'TRY'
    },
    {
      value: 6,
      label: 'JOD'
    },
    {
      value: 7,
      label: 'EUR'
    },
    {
      value: 8,
      label: 'GBP'
    },
    {
      value: 9,
      label: 'SAR'
    },
    {
      value: 10,
      label: 'AED'
    },
    {
      value: 11,
      label: 'KWD'
    },
    {
      value: 12,
      label: 'BHD'
    }
  ]