import React from 'react'
import './style.scss'
import { SearchIcon } from '../../assets/icons/Icons'

function SearchInput({placeholder, onChange, value}) {
    return (
        <>
            <input placeholder={placeholder} onChange={onChange} defaultValue={value} className='search__input' />
            <button className='search-icon right-4'>
                <SearchIcon />
            </button>
        </>
    )
}

export default SearchInput