import React from 'react';
import { UserOutlined, VideoCameraOutlined } from '@ant-design/icons';

// Define and export the menu items
const adminMenuItems = [
  {
    key: 'users',
    icon: <UserOutlined />,
    label: 'Users',
  },
  {
    key: 'passes',
    icon: <UserOutlined />,
    label: 'Passes',
  },
  {
    key: 'categories',
    icon: <VideoCameraOutlined />,
    label: 'Categories',
  },
  {
    key: 'merchants',
    icon: <VideoCameraOutlined />,
    label: 'Merchants',
  },
  {
    key: 'outlets',
    icon: <VideoCameraOutlined />,
    label: 'Outlets',
  },
  {
    key: 'amenities',
    icon: <VideoCameraOutlined />,
    label: 'Amenities',
  },
  {
    key: 'benefits',
    icon: <VideoCameraOutlined />,
    label: 'Benefits',
  },
];

export default adminMenuItems;
