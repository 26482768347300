// 
import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Space, Popconfirm, message, Switch, Tag } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { deleteMerchant, fetchMerchantList } from '../../apiFunctions/merchants';
import Wrapper from '../../layouts/wrapper/wrapper';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { Put } from '../../utils/services/request2';


const Merchants = () => {

    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrorMsg] = useState("");
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    useEffect(() => {
        getMerchanges(currentPage, pageSize);
    }, [currentPage, pageSize]);

    const getMerchanges = useCallback(async (page, limit) => {
        setLoading(true);
        try {
            const offset = (page - 1) * limit;
            const res = await fetchMerchantList({
                offset: offset,
                limit: limit
            });
            setTableData(res?.data?.records || []);
            setTotal(res?.data?.count || 0);
            setErrorMsg(res?.data?.records?.length === 0 ? 'No data found' : '');
            setLoading(false);
        } catch (err) {
            setErrorMsg(err?.response?.data?.message || 'Failed to fetch data');
            setTableData([]);
            setTotal(0);
            setLoading(false)

        }
    }, []);


    const handleTableChange = (pagination) => {
        const { current, pageSize } = pagination;
        if (current !== currentPage || pageSize !== pageSize) {
            setCurrentPage(current);
            setPageSize(pageSize);
        }
    };


    const handleDelete = async (id) => {
        try {
            await deleteMerchant(id);
            message.success('Merchant deleted successfully');
            getMerchanges(currentPage, pageSize);
        } catch (err) {
            message.error('Failed to delete merchant');
        }
    };

    const handleActiveDeactive = async (id, record) => {
        const data = {is_active: !record.is_active}
        try {
         const res = await Put(`${ApiRoutes.Merchant}/${id}`, data);
          message.success(res?.data?.message);
          getMerchanges(currentPage, pageSize);
        } catch (err) {
          message.error('Failed to deactivated merchant');
        }
      };


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Arabic Name',
            dataIndex: 'arabic_name',
            key: 'arabicName',
        },
        {
            title: 'Media',
            dataIndex: 'medias',
            key: 'medias',
            render: (text, record) => {
                return <div className="flex items-center gap-2"> {record?.medias?.[0] ? <img  className='img__in__table' src={record?.medias?.[0]?.path} />:""} </div>
            },
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            width: 200,
            render: (text, record) => {
              return  <Tag color="#c66238">{record?.is_active? "Activated": "Deactivated"}</Tag>
          },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
              <Space size="middle">
                {/* Edit Button */}
                <Button
                  onClick={() => {
                    navigate(`/edit-merchant/${record.id}`);
                  }}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                  icon={<EditOutlined />}
                />
      
                <Popconfirm
                  title={record.is_active ? "Are you sure you want to deactivate this merchant?" : "Are you sure you want to activate this merchant?"}
                  onConfirm={() => handleActiveDeactive(record.id, record)} // Confirm delete action
                  okText="Yes"
                  cancelText="No"
                >

                <Switch checked={record.is_active} />

                </Popconfirm>
              </Space>
            ),
        },
    ]



    const redeem = () => {
        return (
            <div className="responsive-table-container">
                <div className='flex justify-end mb-2'>
                    <Button type='primary' onClick={() => { navigate('/add-merchant') }}>Add Merchant</Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    loading={loading}
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: total,
                    }}
                    onChange={handleTableChange}
                    locale={{ errMsg }}
                    scroll={{ x: 600 }}
                />
            </div>
        )
    }


    return (
        <Wrapper Comp={redeem} />
    );
}

export default Merchants;
