import React, { useState, useCallback, useEffect } from "react";
import { Select } from "antd";
import debounce from "lodash/debounce";

const CustomAutoComplete = ({ form, fetchData, name, placeholder, multiple }) => {
    const [outletOptions, setOutletOptions] = useState([]);
    const [, forceUpdate] = useState(0);

    const debouncedApiCall = useCallback(
        debounce((query) => {
            fetchDataFunc(query)
        }, 300),
        []
    );

    const fetchDataFunc = async (value) => {
        try {
            const data = await fetchData(value);
            setOutletOptions(data);
            forceUpdate(f => f + 1)
        } catch (error) {
            setOutletOptions([])
        }
    }


    useEffect(() => {
        fetchDataFunc()
    }, [])

    return (
        <>
            <Select
                className='antd__inp'
                options={outletOptions}
                mode={multiple && "multiple"}
                placeholder={placeholder}
                showSearch
                value={form.getFieldValue(name)}
                onChange={(value) => { form.setFieldsValue({ [name]: value }) }}
                onBlur={() => fetchDataFunc()}
                onSelect={() => fetchDataFunc()}
                filterOption={false}
                onSearch={(text) => debouncedApiCall(text)}
            />
        </>
    );
};

export default CustomAutoComplete;