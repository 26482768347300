import ApiRoutes from "../utils/apiRoutes/routes";
import { Get, Put } from "../utils/services/request2";


export const fetchOutletList = async (params) => {
    try {
        const response = await Get(ApiRoutes.Outlet, null, params);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const fetchOutletById = async (id) => {
    try {
        const response = await Get(`${ApiRoutes.Outlet}/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const updateOutlet = async (id,data) => {
    try {
        const response = await Put(`${ApiRoutes.Outlet}/${id}`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const deleteOutlet = async (id) => {
    try {
        const response = await Put(`${ApiRoutes.Outlet}/delete/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const createOutlet = async (data) => {
    try {
        const response = await Put(`${ApiRoutes.passes}/create`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}