import ApiRoutes from "../utils/apiRoutes/routes";
import { Get, Post, Put } from "../utils/services/request2";


export const fetchMerchantList = async (params) => {
    try {
        const response = await Get(ApiRoutes.Merchant, null, params);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const fetchMerchantById = async (id) => {
    try {
        const response = await Get(`${ApiRoutes.Merchant}/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const updateMerchant = async (id,data) => {
    try {
        const response = await Put(`${ApiRoutes.Merchant}/${id}`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const deleteMerchant = async (id) => {
    try {
        const response = await Put(`${ApiRoutes.Merchant}/delete/${id}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const createMerchant = async (data) => {
    try {
        const response = await Post(`${ApiRoutes.Merchant}/create`,data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}