import React from 'react'
import './ipass.scss'
function Ipasscard(props) {
    return (
        <div>
            <div className='box__siznig'>
                <p className='pass__text'>{props?.name}</p>
                <p className='id_text'>IPASS ID</p>
                <div className='text_wrapper'>
                    <div>
                        <p className='text_style'>
                            {props?.id}
                        </p>
                    </div>
                    <div>
                        <a  className='link_style'>What is Ipass ID?</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ipasscard