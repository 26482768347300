import React, { useEffect, useState } from "react";
import Wrapper from "../../../../layouts/wrapper/wrapper";
import { Input, DatePicker, Button } from 'antd';
import './style.scss'
import { InputWithlabel } from "../../../../components";
import { createPass, fetchPassById } from "../../../../apiFunctions/passes";
import { useParams } from "react-router-dom";
import { fetchPartnerById, updatePartner } from "../../../../apiFunctions/partners";


const CreatePass = () => {

    const params = useParams();
    const id = params?.id;

    const [data, setData] = useState(
        {
            name: "",
            arabic_name: "",
            city_id: [],
            config: {
                is_payment_enabled: false,
                is_coupon_enabled: false,
                is_app_visible: false
            }
        }
    )

    const priotityOptions = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
    ]

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setData(d => ({ ...d, [name]: value }))
    }

    const handleSubmit = async () => {
        const response = await updatePartner(id, data)
        console.log(response, "data")
    }

    const getPassDetails = async () => {
        const response = await fetchPartnerById(id)
        setData(response?.data)
        console.log("Response", response)
    }

    useEffect(() => {
        getPassDetails()
    }, [])

    return <div className="create__pass__main">
        <h2>
            Create Pass
        </h2>
        <div className="input__section">
            <InputWithlabel onChange={handleChange} value={data?.name} name={"name"} required label={"Name"} placeholder="Name" className="antd__inp" />
            <InputWithlabel onChange={handleChange} value={data?.arabic_name} name={"arabic_name"} required label={"Arabic Name"} placeholder="Arabic Name" className="antd__inp" />
            {/* <InputWithlabel onChange={handleChange} value={data?.price} name={"price"} required label={"Price"} placeholder="Price" type="number" className="antd__inp" />
            <InputWithlabel onChange={handleChange} value={data?.discounted_price} name={"discounted_price"} required label={"Discounted Price"} placeholder="Discounted Price" type="number" className="antd__inp" /> */}
            <div className="flex justify-center w-full">
                <Button onClick={handleSubmit} className="submit__btn">
                    Submit
                </Button>
            </div>
        </div>
    </div>
}


const Index = () => {
    return <>
        <Wrapper Comp={CreatePass} />
    </>
}

export default Index;