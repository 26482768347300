import React, { useState, useEffect } from "react";

import "./wrapper.scss"
import {
  UserOutlined,
  LogoutOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Layout, Menu, Drawer, theme, Typography } from 'antd';
import iPassLogo from '../../assets/images/svg/logo-white.svg'
import colorLogo from "../../assets/images/colorLogo.png"
import { MenuIcon } from '../../assets/icons/Icons';
import backIcon from "../../assets/images/back.png"
import { useAuth } from '../../context';
import ApiRoutes from '../../utils/apiRoutes/routes';
import { Post } from '../../utils/services/request';
import { Decoding } from '../../functions/base64';
import outletMenuItems from './outletMenuListing';
import { userType } from '../../utils/userData/userData';
import adminMenuItems from './adminMenuListing';
import routeMap from "./routeMap";
const { Header, Sider, Content } = Layout;



const Wrapper = ({ Comp, backBtn }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Determine if the screen is mobile initially
  const [selectedKey, setSelectedKey] = useState("");
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };





  useEffect(() => {
    var currentUrl = location.pathname;
    var currentSelectedItem = currentUrl.substring(1);
    if (userType() === "Admin" && !currentUrl.includes("add") && !currentUrl.includes("edit")) {
      setSelectedKey(currentSelectedItem)
    } else if (userType() === "Admin" && (currentUrl.includes("add") || currentUrl.includes("edit"))) {
      console.log("agya he")
      if (currentUrl.includes("edit")) {
        let urlWithoutId = removeIdFromString(currentSelectedItem)
        console.log(urlWithoutId,"withoutid")
        const selectedKey = routeMap[urlWithoutId];
        setSelectedKey(selectedKey)
      } else {
        const selectedKey = routeMap[currentSelectedItem];
        setSelectedKey(selectedKey)
      }
    } else {
      let currentUrl = location.pathname;
      if (currentUrl === "/") {
        setSelectedKey("/")
      } else {
        let currentSelectedItem = currentUrl.substring(1);
        setSelectedKey(currentSelectedItem)
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  const removeIdFromString = (str) => {
    // Check if the string includes "edit-category/"
    if (str.includes("edit")) {
      // Split the string by "/" and return the part before the last segment (id)
      return str.split("/").slice(0, -1).join("/");
    }
    return str; // Return the original string if no match
  };


  // console.log(selectedKey, "yyyyyy")

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleSignOut = async () => {
    const data = JSON.parse(Decoding(localStorage.getItem("userData")));
    const payload = { "refresh_token": data?.refresh_token };
    await Post(ApiRoutes.logout, payload);
    logout()
    localStorage.clear()

  }



  const selectRoute = ({ key }) => {
    if (key === "/") {
      setSelectedKey("/");
      navigate("/");
    }
    if (key === "redemption") {
      setSelectedKey("redemption");
      navigate("/redemption");
    }
    if (key === "benefits") {
      setSelectedKey("benefits");
      navigate("/benefits");
    }
    if (key === "passes") {
      setSelectedKey("passes");
      navigate("/admin/passes/list");
    }
    if (key === "merchants") {
      setSelectedKey("merchants");
      navigate("/merchants");
    }
    if (key === "passes") {
      setSelectedKey("passes");
      navigate("/passes");
    }
    if (key === "outlets") {
      setSelectedKey("outlets");
      navigate("/outlets");
    }
    if (key === "amenities") {
      setSelectedKey("amenities");
      navigate("/admin/amenities/list");
    }
    if (key === "categories") {
      setSelectedKey("categories");
      navigate("/categories");
    }
    if (key === "amenities") {
      setSelectedKey("Amenities");
      navigate("/amenities");
    }
    if (key === "users") {
      setSelectedKey("Users");
      navigate("/users");
    }
  }



  return (
    <Layout>
      {/* Render Sider or Drawer based on screen size */}
      {isMobile ? (
        <Drawer
          visible={!collapsed}
          onClose={toggleCollapsed}
          placement="left"
          closable={false}
          width={300}
        >
          <div className="demo-logo-vertical">
            <img src={iPassLogo} alt="logo" />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={selectedKey}
            onClick={selectRoute}
            className='h-full'
            items={userType() === "Admin" ? adminMenuItems : outletMenuItems}
          />
        </Drawer>
      ) : (
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical">
            <img src={iPassLogo} alt="logo" style={{ fill: "#fff" }} />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            onClick={selectRoute}
            selectedKeys={selectedKey}
            items={userType() === "Admin" ? adminMenuItems : outletMenuItems}
          />
           <Typography style={{color:"#fff",padding:"20px",fontSize:10,position:"fixed",bottom:10,left:10}}>
          Version 1.0.0
        </Typography>
        </Sider>
      )}

      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className='flex' style={isMobile ? { position: 'fixed', width: '100%', background: '#fff' } : {}}>
            <div className='w-1/3'>
              {backBtn ? <img className='backBtn' src={backIcon} /> : isMobile && <Button
                type="text"
                icon={collapsed ? <MenuIcon /> : <MenuIcon />}
                onClick={toggleCollapsed}
                style={{
                  width: 64,
                  height: 64,
                }}
              />}
            </div>
            <div className='w-1/3 block md:hidden flex items-center mb-4'>
             <img className='ml-4' src={colorLogo} alt="logo"/>
            </div>
            <div>
              <Button type="text" onClick={handleSignOut} icon={<LogoutOutlined />} className='signoutBtn'>Sign Out</Button>
            </div>
          </div>

        </Header>
        <Content
          style={
            isMobile ? {
              margin: '24px 0px',
              padding: 12,
              minHeight: "100vh",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              position: 'absolute',
              top: '5rem',
              zIndex: -1,
              width: '100%'
            } :
            {
            margin: '24px 16px',
            padding: 12,
            minHeight: "100vh",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Comp />


        </Content>
      </Layout>
    </Layout>
  );
};
export default Wrapper;
