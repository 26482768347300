import React, { useState } from 'react';
import Wrapper from '../../layouts/wrapper/wrapper';
import Redeem from '../../components/redeem/redeem';

const Home = () => {

  return (
    <>
    <Wrapper Comp={Redeem}/>
    </>
  );
};
export default Home;
