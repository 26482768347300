
import service from "./axiosBase2";

export const Get = (path, headers = null, params) => {
    return service.get(path, { headers:headers && headers,
        params: params
     });
};

export const Post = (path, params, headers = null) => {
    return service.post(path, params, { ...(headers && { headers }) });
};

export const Put = (path, params, headers = null) => {
    return service.put(path, params, { ...(headers && { headers }) });
};


